// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    users: [],
    loading: true,
    error: null
};

const slice = createSlice({
    name: 'homeUsers',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // HAS LOADING
        hasLoading(state, action) {
            state.loading = action.payload;
        },

        // GET USERS INFO
        getUsersSuccess(state, action) {
            state.users = action.payload;
        },

        // FILTER USERS
        filterUsersSuccess(state, action) {
            state.users = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getUsers() {
    return async () => {
        try {
            const response = await axios.get('/api/employees?populate=*&fields=*');
            dispatch(slice.actions.getUsersSuccess(response.data));
            dispatch(slice.actions.hasLoading(false));
        } catch (error) {
            console.log(error.message);
            dispatch(slice.actions.getUsersSuccess([]));
            dispatch(slice.actions.hasError(error.message));
            dispatch(slice.actions.hasLoading(false));
        }
    };
}

export function filterUsers(key) {
    return async () => {
        try {
            const response = await axios.get(`/api/employees?populate=*&fields=*&filters[name][$contains]=${key}`);
            dispatch(slice.actions.filterUsersSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
