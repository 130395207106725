import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// sample page routing
const User = Loadable(lazy(() => import('views/User')));
const Users = Loadable(lazy(() => import('views/Users')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <User />
        },
        {
            path: '/users',
            element: <Users />
        },
        {
            path: '/user/:userID',
            element: <User />
        },
        {
            path: '/user/:userID/:tab',
            element: <User />
        }
    ]
};

export default MainRoutes;
