// third-party
import { combineReducers } from 'redux';

// project imports
import snackbarReducer from './slices/snackbar';
import menuReducer from './slices/menu';
import homeEmployeeInfoReducer from './slices/homeEmployeeInfo';
import homeUsers from './slices/homeUsers';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    snackbar: snackbarReducer,
    menu: menuReducer,
    employeeInfo: homeEmployeeInfoReducer,
    users: homeUsers
});

export default reducer;
