// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    employees: [],
    loadingEmployees: true,
    employeeInfo: {},
    loadingEmployeeInfo: false,
    errorEmployeeInfo: null,
    loadingIdentityInfo: false,
    identityInfo: {},
    loadingPassportInfo: false,
    passportInfo: {},
    loadingMedicalInfo: false,
    medicalInfo: {},
    loadingDrivingInfo: false,
    drivingInfo: {},
    loadingVehicleInfo: false,
    vehicleInfo: {}
};

const slice = createSlice({
    name: 'homeEmployeeInfo',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.errorEmployeeInfo = action.payload;
        },

        // HAS LOADING Employees
        hasLoadingEmployees(state, action) {
            state.loadingEmployees = action.payload;
        },

        // HAS LOADING
        hasLoadingEmployeeInfo(state, action) {
            state.loadingEmployeeInfo = action.payload;
        },

        // GET Employees List
        getEmployeesSuccess(state, action) {
            state.employees = action.payload;
        },

        // GET Filter Employees List
        filterEmployeesSuccess(state, action) {
            state.employees = action.payload;
        },

        // GET Employee Info
        getEmployeeInfoSuccess(state, action) {
            state.employeeInfo = action.payload;
        },

        // HAS Loading Identity Info
        hasLoadingIdentityInfo(state, action) {
            state.loadingIdentityInfo = action.payload;
        },

        // GET Identity Info
        getIdentityInfoSuccess(state, action) {
            state.identityInfo = action.payload;
        },

        // HAS Loading Passport Info
        hasLoadingPassportInfo(state, action) {
            state.loadingPassportInfo = action.payload;
        },

        // GET Passport Info
        getPassportInfoSuccess(state, action) {
            state.passportInfo = action.payload;
        },

        // HAS Loading Medical Info
        hasLoadingMedicalInfo(state, action) {
            state.loadingMedicalInfo = action.payload;
        },

        // GET Medical Info
        getMedicalInfoSuccess(state, action) {
            state.medicalInfo = action.payload;
        },

        // HAS Loading Driving Info
        hasLoadingDrivingInfo(state, action) {
            state.loadingDrivingInfo = action.payload;
        },

        // GET Medical Info
        getDrivingInfoSuccess(state, action) {
            state.drivingInfo = action.payload;
        },

        // HAS Loading Vehicle Info
        hasLoadingVehicleInfo(state, action) {
            state.loadingVehicleInfo = action.payload;
        },

        // GET Vehicle Info
        getVehicleInfoSuccess(state, action) {
            state.vehicleInfo = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getEmployees() {
    return async () => {
        try {
            const response = await axios.get('/api/employees?populate=*&fields=*');
            if (response.status === 200) {
                dispatch(slice.actions.getEmployeesSuccess(response.data.data));
                dispatch(slice.actions.hasLoadingEmployees(false));
            }
        } catch (error) {
            console.log(error.error.message);
            dispatch(slice.actions.getEmployeesSuccess([]));
            dispatch(slice.actions.hasError(error.error.message));
            dispatch(slice.actions.hasLoadingEmployees(false));
        }
    };
}

export function filterEmployees(key) {
    dispatch(slice.actions.hasLoadingEmployees(true));
    return async () => {
        try {
            const response = await axios.get(`/api/employees?populate=*&fields=*&filters[name][$contains]=${key}`);
            dispatch(slice.actions.filterEmployeesSuccess(response.data.data));
            dispatch(slice.actions.hasLoadingEmployees(false));
        } catch (error) {
            console.log(error.error.message);
            dispatch(slice.actions.hasError(error.error.message));
            dispatch(slice.actions.hasLoadingEmployees(false));
        }
    };
}

export function getEmployeeInfo(userID) {
    return async () => {
        try {
            const response = await axios.get(`/api/employees/${userID}?populate=*&fields=*`);
            dispatch(slice.actions.getEmployeeInfoSuccess(response.data.data));
            dispatch(slice.actions.hasLoadingEmployeeInfo(false));
        } catch (error) {
            console.log(error.error.message);
            dispatch(slice.actions.getEmployeeInfoSuccess({}));
            dispatch(slice.actions.hasError(error.error.message));
            dispatch(slice.actions.hasLoadingEmployeeInfo(false));
        }
    };
}

export function getIdentityInfo(identityID) {
    dispatch(slice.actions.hasLoadingIdentityInfo(true));
    return async () => {
        try {
            const response = await axios.get(`/api/identities/${identityID}?populate=*&fields=*`);
            dispatch(slice.actions.getIdentityInfoSuccess(response.data));
            dispatch(slice.actions.hasLoadingIdentityInfo(false));
        } catch (error) {
            console.log(error.error.message);
            dispatch(slice.actions.getIdentityInfoSuccess({}));
            dispatch(slice.actions.hasError(error.error.message));
            dispatch(slice.actions.hasLoadingIdentityInfo(false));
        }
    };
}

export function getPassportInfo(passportID) {
    dispatch(slice.actions.hasLoadingPassportInfo(true));
    return async () => {
        try {
            const response = await axios.get(`/api/passports/${passportID}?populate=*&fields=*`);
            dispatch(slice.actions.getPassportInfoSuccess(response.data));
            dispatch(slice.actions.hasLoadingPassportInfo(false));
        } catch (error) {
            console.log(error.error.message);
            dispatch(slice.actions.getPassportInfoSuccess({}));
            dispatch(slice.actions.hasError(error.error.message));
            dispatch(slice.actions.hasLoadingPassportInfo(false));
        }
    };
}

export function getMedicalInfo(medicalID) {
    dispatch(slice.actions.hasLoadingMedicalInfo(true));
    return async () => {
        try {
            const response = await axios.get(`/api/medicals/${medicalID}?populate=*&fields=*`);
            dispatch(slice.actions.getMedicalInfoSuccess(response.data));
            dispatch(slice.actions.hasLoadingMedicalInfo(false));
        } catch (error) {
            console.log(error.error.message);
            dispatch(slice.actions.getMedicalInfoSuccess({}));
            dispatch(slice.actions.hasError(error.error.message));
            dispatch(slice.actions.hasLoadingMedicalInfo(false));
        }
    };
}

export function getDrivingInfo(drivingID) {
    dispatch(slice.actions.hasLoadingDrivingInfo(true));
    return async () => {
        try {
            const response = await axios.get(`/api/licenses/${drivingID}?populate=*&fields=*`);
            dispatch(slice.actions.getDrivingInfoSuccess(response.data));
            dispatch(slice.actions.hasLoadingDrivingInfo(false));
        } catch (error) {
            console.log(error.error.message);
            dispatch(slice.actions.getDrivingInfoSuccess({}));
            dispatch(slice.actions.hasError(error.error.message));
            dispatch(slice.actions.hasLoadingDrivingInfo(false));
        }
    };
}

export function getVehicleInfo(vehicleID) {
    dispatch(slice.actions.hasLoadingVehicleInfo(true));
    return async () => {
        try {
            const response = await axios.get(`/api/vehicles/${vehicleID}?populate=*&fields=*`);
            dispatch(slice.actions.getVehicleInfoSuccess(response.data));
            dispatch(slice.actions.hasLoadingVehicleInfo(false));
        } catch (error) {
            console.log(error.error.message);
            dispatch(slice.actions.getVehicleInfoSuccess({}));
            dispatch(slice.actions.hasError(error.error.message));
            dispatch(slice.actions.hasLoadingVehicleInfo(false));
        }
    };
}
