import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';
import { DASHBOARD_PATH } from '../../config';

// ==============================|| GUEST GUARD ||============================== //

/**
 * Employee guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */

const EmployeeGuard = ({ children }) => {
    const { isLoggedIn, user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoggedIn) {
            navigate('login', { replace: true });
        }

        if (isLoggedIn && !user.confirmed) {
            navigate(DASHBOARD_PATH, { replace: true });
        }
    }, [isLoggedIn, navigate, user?.blocked, user?.confirmed]);

    return children;
};

EmployeeGuard.propTypes = {
    children: PropTypes.node
};

export default EmployeeGuard;
