import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import EmployeeGuard from '../utils/route-guard/EmployeeGuard';

// Employee page routing
const EmployeePage = Loadable(lazy(() => import('views/pages/maintenance/ComingSoon/ComingSoon1')));

// ==============================|| MAIN ROUTING ||============================== //

const EmployeeRoutes = {
    path: '/',
    element: (
        <EmployeeGuard>
            <MainLayout />
        </EmployeeGuard>
    ),
    children: [
        {
            path: '/employeePage',
            element: <EmployeePage />
        }
    ]
};

export default EmployeeRoutes;
