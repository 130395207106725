// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconUsers } from '@tabler/icons';

// constant
const icons = {
    IconUsers
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
    id: 'sample-docs-roadmap',
    type: 'group',
    children: [
        /* {
            id: 'user',
            title: <FormattedMessage id="user" />,
            type: 'item',
            url: '/user',
            icon: icons.IconBrandChrome,
            breadcrumbs: false
        }, */
        {
            id: 'users',
            title: <FormattedMessage id="users" />,
            type: 'item',
            url: '/users',
            icon: icons.IconUsers,
            breadcrumbs: false
        } /* ,
        {
            id: 'documentation',
            title: <FormattedMessage id="documentation" />,
            type: 'item',
            url: '/employeePage',
            icon: icons.IconHelp,
            external: true,
            target: false
        } */
    ]
};

export default other;
